import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
// import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
// import jwt from 'jsonwebtoken'
// IMPORT MATERIALIZE CSS FRAMEWORK
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize.min.js';

import createRootReducer from 'redux/reducers'
import routes from 'routes'

// import { ADMIN_LOGIN_SUCCESS } from 'redux/actionTypes'
// import setAuthorizationToken from 'utils/setAuthorizationToken'
// import verifyToken from 'utils/verifyToken'

//Auth0 Config
import { Auth0Provider } from "utils/react-auth0-spa";
import config from "utils/auth_config.json";

// const history = createBrowserHistory() 
import history from 'utils/history'

const middlewares = [thunk, routerMiddleware(history)]
export const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(...middlewares))
)

//AUTH0 CONFIG *************************************************

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};



// if (localStorage.jwtToken) {

//   try {
//     let decoded = jwt.decode(localStorage.jwtToken);
//     if (verifyToken()) {
//       setAuthorizationToken(localStorage.jwtToken);
//       store.dispatch({
//         type: ADMIN_LOGIN_SUCCESS,
//         payload: decoded
//       })
//     }

//   }
//   catch (err) {

//   }

// }


ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <ConnectedRouter history={history}>
        {routes}
      </ConnectedRouter>
    </Auth0Provider>

  </Provider>,
  document.getElementById('root')
)